import React from "react"
import Font42 from "components/Fonts/Font42"
import Font18 from "components/Fonts/Font18"
import { graphql, useStaticQuery } from "gatsby"
import RecrutationStep from "./RecrutationStep/RecrutationStep"
import { Grid } from "@mui/material"
import Gallery from "./Gallery/Gallery"
import Link from "components/Buttons/Link"

const RecrutationsSteps = () => {
  const query = useStaticQuery(graphql`
    query RecrutationStepsShort {
      allContentfulEtapyRekrutacji {
        nodes {
          icon {
            icon
          }
          contentful_id
          title
          order
          shortDescription
        }
      }
    }
  `)

  const recrutationSteps = query.allContentfulEtapyRekrutacji.nodes

  return (
    <section>
      <Font42 ta={"center"} sx={{ textAlign: "center", mb: 11 }}>
        Etapy rekrutacji Opiekunki Osób Starszych
      </Font42>
      <Grid container justifyContent={"center"} mb={8}>
        {recrutationSteps
          .sort((a, b) => a.order - b.order)
          .map((item, i) => (
            <RecrutationStep
              {...item}
              key={item.contentful_id}
              isLast={recrutationSteps.length === i + 1}
            />
          ))}
      </Grid>
      <Gallery />
      <Grid container justifyContent="center" mb={15}>
        <Font18 color="secondary">
          <Link
            to="/dla-opiekunki/etapy-rekrutacji-opiekunki-osob-starszych"
            underline
            color="secondary"
          >
            Dowiedz się więcej o naszej rekrutacji
          </Link>
        </Font18>
      </Grid>
    </section>
  )
}

export default RecrutationsSteps
