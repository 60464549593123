import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export const StyledGatsbyImage = styled(GatsbyImage)`
  position: absolute;
  left: 10px;
  top: 15px;
  z-index: 99;
  overflow: visible;

  img {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  }
`
