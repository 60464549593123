import React, { useEffect, useState } from "react"
import { Wrapper } from "./styles"

const CarouselHOC = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true)
    }, 200)
  }, [])

  if (isLoaded) {
    return <Wrapper>{children}</Wrapper>
  }
  return <Wrapper />
}

export default CarouselHOC
