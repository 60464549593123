import styled from "styled-components"
import { theme } from "theme/theme"

export const Icon = styled.div`
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.palette.secondary.main};
  color: white;
  svg {
    fill: white;
    path {
      fill: white;
    }
  }
`
