import React from "react"
import Font42 from "components/Fonts/Font42"
import Font18 from "components/Fonts/Font18"
import { graphql, useStaticQuery } from "gatsby"
import JobOfferCard from "./components/JobOfferCard/JobOfferCard"
import { Grid, useMediaQuery } from "@mui/material"
import Link from "components/Buttons/Link"

const JobOffersForNanny = () => {
  const query = useStaticQuery(graphql`
    query JobOffersForNanny {
      allContentfulOfertaPracyDlaOpiekunki2(
        limit: 4
        sort: { fields: createdAt, order: DESC }
        filter: { city: { ne: "TEST" } }
      ) {
        nodes {
          city
          contentful_id
          orderType
          salary
          currency
          country
          startDate(formatString: "DD.MM.yyyy")
        }
        totalCount
      }
    }
  `)

  const jobOffersForNanny =
    query.allContentfulOfertaPracyDlaOpiekunki2.nodes || []
  const isMin1024 = useMediaQuery("(min-width:1057px)")

  return (
    <Grid mb={15}>
      <section>
        <Font42 textAlign="center" mb={5}>
          Oferty pracy dla Opiekunki Osób Starszych
        </Font42>
        <Grid
          container
          justifyContent="center"
          spacing={{ md: 5, lg: 7 }}
          rowSpacing={4}
          alignItems="center"
          mb={4}
        >
          {jobOffersForNanny.length ? (
            jobOffersForNanny.map(offer => (
              <Grid
                item
                xs={11}
                sm={6}
                md={isMin1024 ? 3 : 4}
                key={offer.contentful_id}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <JobOfferCard {...offer} />
              </Grid>
            ))
          ) : (
            <Font18 sx={{ ml: 5 }} ta="center">
              Brak ofert pracy
            </Font18>
          )}
        </Grid>
        <Grid container direction="column" alignItems="center">
          <Font18 color="secondary">
            Nie znalazłaś/eś nic dla siebie? Nic straconego!
          </Font18>
          <Font18 color="secondary">
            <Link
              to="/dla-opiekunki/oferty-pracy-dla-opiekunki-osob-starszych"
              underline
            >
              Zobacz oferty pracy dla Opiekunki Osób Starszych
            </Link>
          </Font18>
        </Grid>
      </section>
    </Grid>
  )
}

export default JobOffersForNanny
