export function hexToRgba(hex, opacity = 0.65) {
  hex = hex.replace(/^#/, "")
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map(char => char + char)
      .join("")
  }
  let r = parseInt(hex.slice(0, 2), 16)
  let g = parseInt(hex.slice(2, 4), 16)
  let b = parseInt(hex.slice(4, 6), 16)

  let rgba = `rgba(${r}, ${g}, ${b}, ${opacity})`

  return rgba
}
