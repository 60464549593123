import { useMediaQuery, useTheme } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import CarouselHOC from "../CarouselHOC/CarouselHOC"
import SliderCard from "../SliderCard/SliderCard"
import { CarouselWrapper } from "./style"

const CarouselContainer = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const query = useStaticQuery(graphql`
    query CarouselQuery {
      allContentfulKaruzela(filter: { order: { ne: 404 } }) {
        nodes {
          buttonAction
          buttonText
          description
          order
          link
          title
          contentful_id
          buttonLabel
          buttonsAlignment
          secondButtonLabel
          secondButtonText
          secondLink
          slideFilterColor
          image {
            gatsbyImageData(
              height: 600
              backgroundColor: "transparent"
              quality: 95
              placeholder: NONE
            )
          }
        }
      }
    }
  `)

  const sortedCarouselCards = query.allContentfulKaruzela.nodes.sort(
    (a, b) => a.order - b.order
  )

  return (
    <CarouselHOC>
      <CarouselWrapper
        stopAutoPlayOnHover
        isMobile={isMobile}
        IndicatorIcon={<span />}
        indicatorContainerProps={{
          style: {
            marginTop: "-60px",
            marginBottom: "15px",
            position: "relative",
            zIndex: 1,
          },
        }}
        indicatorIconButtonProps={{
          style: {
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: "#3BB2C4",
            border: "1px solid #FFFFFF",
            boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
            margin: "0 3px",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: "white",
            border: "1px solid #FFFFFF",
            boxShadow: "none",
          },
        }}
        navButtonsAlwaysInvisible
      >
        {sortedCarouselCards.map((item, i) => {
          return <SliderCard {...item} key={item.contentful_id} />
        })}
      </CarouselWrapper>
    </CarouselHOC>
  )
}

export default CarouselContainer
