import { Grid, useMediaQuery, useTheme } from "@mui/material"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Icon } from "./styles"
import Font18 from "components/Fonts/Font18"
import Font12 from "components/Fonts/Font12"

const RecrutationStep = ({
  order,
  icon,
  title,
  shortDescription,
  isLast,
  ...rest
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Grid item mb={4} xs={12} md={"auto"} mx={-1} {...rest}>
      <Grid
        container
        wrap={isMobile ? "wrap" : "nowrap"}
        key={order}
        justifyContent={isMobile ? "center" : "flex-start"}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            maxWidth: isMobile ? "auto" : "215px",
            padding: isMobile ? "0 10px" : 0,
            width: isMobile && "100%",
          }}
          justifyContent={"center"}
        >
          <Grid
            item
            xs={12}
            sm={7}
            md={12}
            sx={{ display: "flex", flexDirection: "column" }}
            alignItems={"center"}
          >
            <Grid item mb={2}>
              <Icon dangerouslySetInnerHTML={{ __html: icon.icon }} />
            </Grid>
            <Grid item>
              <Font18 fw={900} align="center" mb={1}>
                {title}
              </Font18>
            </Grid>
            <Grid item>
              <Font12 color="secondary" align="center">
                {shortDescription}
              </Font12>
            </Grid>
          </Grid>
        </Grid>
        {!isLast && (
          <Grid
            item
            mt={isMobile ? 5 : 3}
            ml={-1}
            sx={{ transform: isMobile ? "rotate(90deg)" : "rotate(0)" }}
            mb={isMobile ? 3 : 0}
          >
            <StaticImage
              src="../../../../images/recruitment/arrows.png"
              width={71}
              quality={90}
              formats={["auto", "webp", "avif"]}
              alt="Strzałki"
              placeholder="transparent"
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default RecrutationStep
