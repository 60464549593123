import { Card, Chip, Grid } from "@mui/material"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { ImageWrapper } from "./styles"
import Font22 from "components/Fonts/Font22"
import Font12 from "components/Fonts/Font12"
import Button from "components/Buttons/Button"

const NewsPostCard = ({
  title,
  mainImage,
  createdAt,
  shortDescription,
  slug,
}) => {
  const image = getImage(mainImage)
  return (
    <Card sx={{ padding: 0, maxWidth: 250 }}>
      <Chip label={createdAt} size="small" variant="primary-right" />
      <Grid container>
        <ImageWrapper>
          <Font22 color="white">{title}</Font22>
          <GatsbyImage image={image} alt={title} />
        </ImageWrapper>

        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          px={4}
          py={3}
        >
          <Grid sx={{ minHeight: 90, alignSelf: "flex-start" }}>
            <Font12 color="secondary" textAlign="justify">
              {shortDescription}
            </Font12>
          </Grid>
          <Button style={{ marginTop: 16 }} to={`/aktualnosci/${slug}`}>
            Czytaj więcej
          </Button>
        </Grid>
      </Grid>
    </Card>
  )
}

export default NewsPostCard
