import React, { useState } from "react"
import { StyledGatsbyImage, Warpper } from "./style"
import Font32 from "components/Fonts/Font32"
import Font22 from "components/Fonts/Font22"
import Font16 from "components/Fonts/Font16"
import Button from "components/Buttons/Button"
import { Grid, useMediaQuery, useTheme } from "@mui/material"
import ApplicationFormModal from "components/AppliccationFormModal/ApplicationFormModal"

const SliderCard = ({
  image,
  title,
  description,
  buttonText,
  buttonLabel,
  buttonsAlignment,
  secondButtonLabel,
  secondButtonText,
  secondLink,
  slideFilterColor,
  link,
  buttonAction,
  ...rest
}) => {
  const [openModal, setOpenModal] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const action =
    buttonAction === "Otwórz modal z formularzem rejestracji zgłoszenia"
      ? "OPEN_MODAL"
      : null

  const handleClick = () => {
    if (action === "OPEN_MODAL") {
      setOpenModal(true)
    }
  }

  const direction = buttonsAlignment === "Poziomo" ? "row" : "column"

  return (
    <Grid {...rest}>
      <StyledGatsbyImage image={image.gatsbyImageData} alt={title} />
      <Warpper isMobile={isMobile} slideFilterColor={slideFilterColor}>
        <Font32 color="white" sx={{ textAlign: "center" }}>
          {title}
        </Font32>
        {description && (
          <Font22 color="white" fw={400} sx={{ textAlign: "center" }}>
            {description}
          </Font22>
        )}
        <Grid
          container
          alignItems={direction === "column" ? "center" : "flex-end"}
          justifyContent="center"
          flexDirection={direction}
          columnGap={8}
          rowGap={4}
          mt={4}
        >
          {buttonText && (
            <Grid
              item
              container
              sx={{ width: "fit-content" }}
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              {buttonLabel && (
                <Font16 mb={1} color="white">
                  {buttonLabel}
                </Font16>
              )}
              <Button
                to={link || null}
                component={link ? "anchor" : null}
                onClick={handleClick}
              >
                {buttonText}
              </Button>
            </Grid>
          )}
          {secondButtonText && (
            <Grid
              item
              sx={{ width: "fit-content" }}
              container
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              {secondButtonLabel && (
                <Font16 mb={1} color="white">
                  {secondButtonLabel}
                </Font16>
              )}
              <Button
                to={link || null}
                component={secondLink ? "anchor" : null}
                onClick={handleClick}
              >
                {secondButtonText}
              </Button>
            </Grid>
          )}
        </Grid>

        {openModal && (
          <ApplicationFormModal
            openModal={openModal}
            onClose={() => setOpenModal(false)}
          />
        )}
      </Warpper>
    </Grid>
  )
}

export default SliderCard
