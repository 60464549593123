import React from "react"
import { Grid, useMediaQuery, useTheme } from "@mui/material"
import Carousel from "./components/Carousel/Carousel"

const HomepageHeader = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Grid
      container
      justifyContent={"flex-end"}
      direction={isMobile ? "column-reverse" : "row"}
      sx={{
        position: "relative",
        width: "96vw",
        transform: "translateX(-50%)",
        left: "50%",
        maxWidth: "1536px",
      }}
    >
      <Grid
        item
        xs={12}
        // sx={{ mr: { xs: -2, sm: -3 }, ml: { xs: -2, sm: -3, lg: 0 } }}
      >
        <Carousel />
      </Grid>
    </Grid>
  )
}

export default HomepageHeader
