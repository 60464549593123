import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { hexToRgba } from "../../../../../shared/hexToRgba"

export const Warpper = styled.div`
  background: ${({ slideFilterColor }) =>
    slideFilterColor
      ? hexToRgba(slideFilterColor, 0.65)
      : "rgba(59, 178, 196, 0.65)"};
  background: ${({ slideFilterColor }) =>
    slideFilterColor === "#000000" ? "none" : "x"};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ isMobile }) => (isMobile ? "20px" : "80px")};
  flex-direction: column;
`

export const StyledGatsbyImage = styled(GatsbyImage)`
  max-height: 600px;
  min-height: 600px;
  width: 100%;
  img {
    object-position: 50% top;
  }
`
