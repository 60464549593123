import { Grid } from "@mui/material"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Gallery = () => {
  return (
    <Grid container alignItems="center" justifyContent="center" mb={4}>
      <StaticImage
        src="../../../../images/recruitment/recruitment_1.png"
        width={367}
        quality={90}
        formats={["auto", "webp", "avif"]}
        alt="Konsultantka"
        placeholder="transparent"
      />
      <StaticImage
        src="../../../../images/recruitment/recruitment_2.png"
        width={465}
        quality={90}
        formats={["auto", "webp", "avif"]}
        alt="Konsultantki"
        placeholder="transparent"
      />
      <StaticImage
        src="../../../../images/recruitment/recruitment_3.png"
        width={367}
        quality={90}
        formats={["auto", "webp", "avif"]}
        alt="Rozmowa rekrutacyjna"
        placeholder="transparent"
      />
    </Grid>
  )
}

export default Gallery
