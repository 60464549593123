import { Card, Chip, Grid } from "@mui/material"
import React from "react"
import Font24 from "components/Fonts/Font24"
import Font16 from "components/Fonts/Font16"
import Button from "components/Buttons/Button"
import { graphql, useStaticQuery } from "gatsby"
import { StyledGatsbyImage } from "./styles"

const JobOfferCard = ({
  contentful_id,
  orderType,
  city,
  salary,
  currency,
  startDate,
  country,
}) => {
  const data = useStaticQuery(graphql`
    query {
      germanFlag: file(name: { eq: "german_flag" }, extension: { eq: "png" }) {
        id
        childImageSharp {
          gatsbyImageData(
            width: 30
            quality: 100
            backgroundColor: "transparent"
            placeholder: BLURRED
          )
        }
      }
      polishFlag: file(name: { eq: "polish_flag" }) {
        childImageSharp {
          gatsbyImageData(
            width: 30
            backgroundColor: "transparent"
            quality: 100
            placeholder: NONE
          )
        }
      }
    }
  `)
  const germanFlag = data.germanFlag.childImageSharp.gatsbyImageData
  const polishFlag = data.polishFlag.childImageSharp.gatsbyImageData
  const variant =
    orderType === "Stałe zlecenie" ? "primary-right" : "secondary-right"

  return (
    <Card
      key={contentful_id}
      style={{ padding: "66px 40px 40px" }}
      sx={{ maxWidth: "270px" }}
    >
      <StyledGatsbyImage
        image={country === "Polska" ? polishFlag : germanFlag}
        alt={country}
      />
      <Chip label={orderType} size="small" variant={variant} />

      <Font24 textAlign="center" fw={400} mb={2}>
        {salary ? `${salary} ${currency === "PLN" ? "zł" : "€"}` : null}
        <br /> <b>{city}</b>
      </Font24>

      <Font16 textAlign="center">
        Wyjazd od: <b>{startDate}</b>
      </Font16>
      <Grid mt={5} container alignItems="center" justifyContent="center">
        <Button to={`/dla-opiekunki/oferta/${contentful_id}`}>
          Zobacz ofertę
        </Button>
      </Grid>
    </Card>
  )
}

export default JobOfferCard
