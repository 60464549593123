import styled from "styled-components"

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  h6 {
    position: absolute;
    z-index: 9;
    width: 80%;
    text-align: center;
    margin-top: 20px;
  }
  ::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(59, 178, 196, 0.7);
  }
`
